<vg-player id="container_{{ uuid }}" class="videoContainer" (mouseenter)="onVideoEnter()" (click)="onVideoEnter()" (mouseleave)="onVideoLeave()">
  <video
    #myMedia
    [vgMedia]="myMedia"
    id="{{ uuid }}"
    poster="{{ posterimage }}"
    preload="auto"
    crossorigin
    allowfullscreen="allow">
    <source src="{{ videourlMp4 }}" type='video/mp4'>
    <source src="{{ videourlOgv }}" type='video/ogv'>
    <source src="{{ videourlWebm }}" type='video/webm'>
    <source src="{{ videourlM4v }}" type='video/m4v'>
    <track kind="subtitles" src="{{ subtitles }}" label="On"  default>
  </video>

  <vg-overlay-play></vg-overlay-play>
  <vg-buffering></vg-buffering>

  <vg-controls class="videoPlayer__controls" [vgAutohide]="true">
    <vg-play-pause></vg-play-pause>
    <vg-time-display vgProperty="current" vgFormat="mm:ss" class="videoPlayerControls__timeCurrent"></vg-time-display>

    <vg-scrub-bar>
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>

    <vg-time-display vgProperty="total" vgFormat="mm:ss" class="videoPlayerControls__timeTotal"></vg-time-display>

    <vg-mute></vg-mute>
    <vg-volume></vg-volume>

    <vg-track-selector></vg-track-selector>
    <vg-fullscreen></vg-fullscreen>
  </vg-controls>

</vg-player>
